import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Credentials } from '../auth/auth.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent implements OnInit {
  @Input() errorMessage: string | null = null;

  @Output() loginEvent = new EventEmitter<Credentials>();

  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required]);

  constructor() {}

  ngOnInit(): void {
    if (!environment.production) {
      this.email.setValue('admin@livesub.com');
      this.password.setValue('12345678');
    }
  }

  login(): void {
    this.loginEvent.emit({
      email: this.email.value,
      password: this.password.value,
    });
  }
}
