import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { ClientPageComponent } from './client-page/client-page.component';
import { DisplayPageComponent } from './display-page/display-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { DisplayLayoutComponent } from './output-layout/display-layout.component';
import { PasswordRecoveryPageComponent } from './password-recovery-page/password-recovery-page.component';
import { TranscriptPageComponent } from './transcript-page/transcript-page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'display',
  },
  {
    path: 'main',
    component: MainLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'client',
        component: ClientPageComponent,
        children: [],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'client',
      },
    ],
  },
  {
    path: 'display',
    component: DisplayLayoutComponent,
    canActivate: [], // AuthGuardService
    children: [
      {
        path: ':identifier/:component',
        component: DisplayPageComponent,
        children: [],
      },
      {
        path: ':identifier',
        component: DisplayPageComponent,
        children: [],
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'site/main',
      },
    ],
  },
  {
    path: 'transcript',
    component: DisplayLayoutComponent,
    canActivate: [], // AuthGuardService
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: TranscriptPageComponent,
        children: [],
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginPageComponent,
        children: [],
      },
      {
        path: 'password',
        component: PasswordRecoveryPageComponent,
        children: [],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'display',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
