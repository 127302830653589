<div class="p-d-flex">
  <p-card class="p-sm-4 p-sm-offset-4" header="Welcome to LiveSub">
    <div class="p-fluid">
      <div class="p-field">
        <input [formControl]="email" id="login" pInputText placeholder="Login" type="text" />
      </div>
      <div class="p-field">
        <input [formControl]="password" id="password" pInputText placeholder="Password" type="password" />
        <p><a routerLink="/auth/password">Forgot your password?</a></p>
      </div>
      <p *ngIf="errorMessage" class="p-error p-text-center">{{ errorMessage }}</p>
    </div>
    <p-footer>
      <div class="p-grid p-my-0 p-mx-0">
        <div class="p-sm-6 p-sm-offset-3">
          <button (click)="login()" label="Sign in" pButton style="width: 100%" type="button"></button>
        </div>
      </div>
    </p-footer>
  </p-card>
</div>
