import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, Credentials } from '../auth/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  errorMessage: string | null = null;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.authService.removeToken();
  }

  login(credentials: Credentials): void {
    this.authService.login(credentials).subscribe((value) => {
      if (value) {
        this.errorMessage = null;
        this.router.navigate(['/main']).then();
      } else {
        this.errorMessage = 'Login failed';
      }
    });
  }
}
