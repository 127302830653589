<div class="p-d-flex p-ai-center p-jc-between p-px-3 livesub-navigation">
  <h1 style="font-family: sans-serif">LiveSub</h1>

  <div>
    <a routerLink="/auth/login"><i class="pi pi-sign-out" pTooltip="Sign out" style="font-size: 1.5em"></i></a>
  </div>
</div>

<div class="p-d-flex p-m-0 p-flex-row">
  <div class="p-d-flex p-flex-column livesub-main">
    <div class="livesub-main-content p-p-0">
      <router-outlet></router-outlet>
    </div>
    <div class="p-d-flex p-jc-center p-ai-center livesub-footer">
      <p style="font-family: sans-serif">&copy; 2020-2022 Universal Multimedia Access Srl</p>
    </div>
  </div>
</div>
