import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

export interface Credentials {
  email: string;
  password: string;
}

interface TokenResult {
  access_token: string;
  token_type: string;
}

export interface TokenData {
  type: 'user';
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  removeToken(): void {
    localStorage.removeItem('token');
  }

  decodeToken(): TokenData {
    return new JwtHelperService().decodeToken(this.getToken());
  }

  login(credentials: Credentials): Observable<boolean> {
    this.removeToken();

    const formData = new FormData();

    formData.append('username', credentials.email);
    formData.append('password', credentials.password);

    return new Observable<boolean>((subscriber) => {
      this.http.post<TokenResult>('/api/token', formData).subscribe(
        (tokenResult) => {
          this.setToken(tokenResult.access_token);

          subscriber.next(true);
        },
        (error) => {
          subscriber.next(false);
        }
      );
    });
  }
}
