import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private jwtHelperService: JwtHelperService, private router: Router) {}

  private static getToken(): string {
    return localStorage.getItem('token');
  }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['auth', 'login']).then();
      return false;
    }

    return true;
  }

  private isAuthenticated(): boolean {
    return !this.jwtHelperService.isTokenExpired(AuthGuardService.getToken());
  }
}
