<style type="text/css"></style>

<div class="p-d-flex p-ai-center p-jc-between p-px-3 livesub-navigation">
  <h1 style="font-family: sans-serif">LiveSub</h1>

  <div>
    <!-- placehoder for buttons -->
  </div>
</div>

<div class="p-d-flex p-m-0 p-flex-row">
  <div class="p-d-flex p-flex-column livesub-main">
    <div class="livesub-main-content p-p-0 auth-background">
      <div class="p-d-flex p-flex-column p-jc-center" style="flex: 1">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="p-d-flex p-jc-center p-ai-center livesub-footer">
      <p style="font-family: sans-serif">&copy; 2020-2022 Universal Multimedia Access Srl</p>
    </div>
  </div>
</div>
