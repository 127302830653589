<div class="p-d-flex p-flex-column" id="container">
  <p-menubar [model]="menuBarItems">
    <div class="p-d-flex">
      <p-button (onClick)="download()" class="p-button-info p-mr-2" icon="pi pi-fw pi-download" label="Download"></p-button>
    </div>
  </p-menubar>

  <div
    [innerText]="text"
    [style.font-size.px]="configuration.transcriptFontSize"
    class="p-d-flex p-flex-column p-m-3"
    style="flex: 1; overflow-y: auto"
  ></div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" styleClass="settings-dialog"></p-confirmDialog>

<p-toast position="top-right"></p-toast>

<p-dialog [(visible)]="isSettingsDialogVisible" header="Settings" styleClass="settings-dialog">
  <p-tabView>
    <p-tabPanel header="Font">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label class="p-col-4" for="transcriptFontSizeInput">Transcript</label>
          <div class="p-col-8">
            <p-inputNumber
              [(ngModel)]="configuration.transcriptFontSize"
              [max]="FONT_SIZE_MAX"
              [min]="FONT_SIZE_MIN"
              [showButtons]="true"
              [step]="FONT_SIZE_STEP"
              [useGrouping]="false"
              id="transcriptFontSizeInput"
            ></p-inputNumber>

            <p-slider
              [(ngModel)]="configuration.transcriptFontSize"
              [max]="FONT_SIZE_MAX"
              [min]="FONT_SIZE_MIN"
              [step]="FONT_SIZE_STEP"
              id="transcriptFontSizeSlider"
            ></p-slider>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <p-footer>
    <div class="p-d-flex p-jc-between">
      <button (click)="resetSettings()" class="p-button-warning" icon="pi pi-trash" label="Reset" pButton type="button"></button>
      <button (click)="dismissSettings()" class="p-button-success" icon="pi pi-check" label="OK" pButton type="button"></button>
    </div>
  </p-footer>
</p-dialog>
