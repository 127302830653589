<div class="p-d-flex p-flex-column" style="position: relative; flex: 1">
  <p-menubar [model]="menuBarItems">
    <div class="p-d-flex">
      <p-toggleButton
        (ngModelChange)="onConfigurationChange()"
        [(ngModel)]="configuration.subtitleAnimation"
        class="p-button-info p-mr-2"
        offIcon="pi pi-play"
        offLabel=""
        onIcon="pi pi-play"
        onLabel=""
        pTooltip="Subtitle animation"
        tooltipPosition="bottom"
      ></p-toggleButton>

      <p-toggleButton
        (ngModelChange)="onConfigurationChange()"
        [(ngModel)]="configuration.subtitleAsInlineText"
        class="p-button-info p-mr-2"
        offIcon="pi pi-book"
        offLabel=""
        onIcon="pi pi-book"
        onLabel=""
        pTooltip="Subtitle as inline text"
        tooltipPosition="bottom"
      ></p-toggleButton>

      <p-toggleButton
        (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
        [(ngModel)]="configuration.showInvisibles"
        class="p-button-info p-mr-2"
        offIcon="pi pi-question-circle"
        offLabel=""
        onIcon="pi pi-question-circle"
        onLabel=""
        pTooltip="Show whitespace"
        tooltipPosition="bottom"
      ></p-toggleButton>

      <div class="p-input-icon-left p-ml-2">
        <i class="pi pi-search"></i>
        <label for="searchInput"></label>
        <input (input)="filterBySearchInput()" [(ngModel)]="search" id="searchInput" pInputText placeholder="Search" type="text" />
      </div>

      <p-button
        (onClick)="clearSearchInput()"
        class="p-ml-2"
        icon="pi pi-times"
        iconPos="left"
        label="Clear"
        pTooltip="Clear search filter"
        tooltipPosition="bottom"
      ></p-button>
    </div>
  </p-menubar>

  <div class="p-grid p-nogutter" style="height: 100%">
    <div class="p-sm-6 p-nogutter" style="height: 100%">
      <label for="textEditorElement"></label>
      <div
        #textEditorElement
        [style.font-family]="'monospace'"
        [style.font-size.px]="configuration.textEditorFontSize"
        class="app-ace-editor text-editor"
        id="textEditorElement"
      ></div>
    </div>

    <div class="p-d-flex p-flex-column p-sm-6 p-nogutter" style="height: 100%">
      <p-table
        #table
        (onRowReorder)="onDocumentProxyReorder()"
        (selectionChange)="onDocumentProxyChanged($event)"
        [columns]="['title']"
        [globalFilterFields]="['title']"
        [scrollHeight]="configuration.tableScrollHeight + 'px'"
        [scrollable]="true"
        [selection]="selectedSubtitleProxy"
        [value]="subtitleProxies"
        dataKey="identifier"
        selectionMode="single"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Subtitle files</th>
          </tr>
        </ng-template>

        <ng-template let-index="rowIndex" let-rowData pTemplate="body">
          <tr [pReorderableRow]="index" [pSelectableRow]="rowData">
            <td>
              <i class="pi pi-bars p-mr-2" pReorderableRowHandle></i>
              {{ rowData.title }}
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="p-mt-3" style="flex: 1">
        <label for="subtitleTextEditorElement"></label>
        <!-- [readOnly]="this.currentSubtitleProxy === null" -->
        <div
          #subtitleTextEditorElement
          (keydown)="onSubtitleEditorKeyDown($event)"
          [style.font-family]="'monospace'"
          [style.font-size.px]="configuration.subtitleTextEditorFontSize"
          class="app-ace-editor text-editor"
          id="subtitleTextEditorElement"
        ></div>
      </div>
    </div>
  </div>

  <div>
    <div [innerText]="getSpeechLabel()" class="p-mx-3 p-my-3 p-text-nowrap p-text-truncate"></div>
  </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" styleClass="settings-dialog"></p-confirmDialog>

<p-toast position="top-right"></p-toast>

<p-dialog
  [(visible)]="isNewDialogVisible"
  closeOnEscape="true"
  dismissableMask="true"
  header="New file"
  modal="true"
  styleClass="settings-dialog"
>
  <form (ngSubmit)="onNewFile()">
    <div class="p-formgroup-inline">
      <div class="p-field" style="flex: 1">
        <label class="p-sr-only" for="newTitleValue">Title</label>
        <input [(ngModel)]="dialogTitleText" autofocus id="newTitleValue" name="value" pInputText style="width: 100%" type="text" />
      </div>
      <button
        [disabled]="!validDocumentTitleText()"
        class="p-button-success"
        icon="pi pi-check"
        label="Save"
        pButton
        type="submit"
      ></button>
    </div>
  </form>
</p-dialog>

<p-dialog
  [(visible)]="isRenameDialogVisible"
  closeOnEscape="true"
  dismissableMask="true"
  header="Rename"
  modal="true"
  styleClass="settings-dialog"
>
  <form (ngSubmit)="onRenameFile()">
    <div class="p-formgroup-inline">
      <div class="p-field" style="flex: 1">
        <label class="p-sr-only" for="renameTitleValue">Title</label>
        <input [(ngModel)]="dialogTitleText" autofocus id="renameTitleValue" name="value" pInputText style="width: 100%" type="text" />
      </div>
      <button
        [disabled]="!validDocumentTitleText()"
        class="p-button-success"
        icon="pi pi-check"
        label="Rename"
        pButton
        type="submit"
      ></button>
    </div>
  </form>
</p-dialog>

<p-dialog [(visible)]="isSettingsDialogVisible" header="Settings" styleClass="settings-dialog">
  <p-tabView>
    <p-tabPanel header="Font">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label class="p-col-4" for="textEditorFontSizeInput">Transcript</label>
          <div class="p-col-8">
            <p-inputNumber
              [(ngModel)]="configuration.textEditorFontSize"
              [max]="FONT_SIZE_MAX"
              [min]="FONT_SIZE_MIN"
              [showButtons]="true"
              [step]="FONT_SIZE_STEP"
              [useGrouping]="false"
              id="textEditorFontSizeInput"
            ></p-inputNumber>

            <p-slider
              [(ngModel)]="configuration.textEditorFontSize"
              [max]="FONT_SIZE_MAX"
              [min]="FONT_SIZE_MIN"
              [step]="FONT_SIZE_STEP"
              id="textEditorFontSizeSlider"
            ></p-slider>
          </div>
        </div>

        <div class="p-field p-grid">
          <label class="p-col-4" for="subtitleTextEditorFontSizeInput">Subtitles</label>
          <div class="p-col-8">
            <p-inputNumber
              [(ngModel)]="configuration.subtitleTextEditorFontSize"
              [max]="FONT_SIZE_MAX"
              [min]="FONT_SIZE_MIN"
              [showButtons]="true"
              [step]="FONT_SIZE_STEP"
              [useGrouping]="false"
              id="subtitleTextEditorFontSizeInput"
            ></p-inputNumber>

            <p-slider
              [(ngModel)]="configuration.subtitleTextEditorFontSize"
              [max]="FONT_SIZE_MAX"
              [min]="FONT_SIZE_MIN"
              [step]="FONT_SIZE_STEP"
              id="subtitleTextEditorFontSizeSlider"
            ></p-slider>
          </div>
        </div>

        <div class="p-field p-grid">
          <label class="p-col-4" for="tableScrollHeightInput">Table height</label>
          <div class="p-col-8">
            <p-inputNumber
              (ngModelChange)="onTableScrollHeightChanged()"
              [(ngModel)]="configuration.tableScrollHeight"
              [max]="TABLE_SCROLL_HEIGHT_MAX"
              [min]="TABLE_SCROLL_HEIGHT_MIN"
              [showButtons]="true"
              [step]="TABLE_SCROLL_HEIGHT_STEP"
              [useGrouping]="false"
              id="tableScrollHeightInput"
            ></p-inputNumber>

            <p-slider
              (ngModelChange)="onTableScrollHeightChanged()"
              [(ngModel)]="configuration.tableScrollHeight"
              [max]="TABLE_SCROLL_HEIGHT_MAX"
              [min]="TABLE_SCROLL_HEIGHT_MIN"
              [step]="TABLE_SCROLL_HEIGHT_STEP"
              id="tableScrollHeightSlider"
            ></p-slider>
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Subtitles">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label class="p-col-4" for="subtitleRowCountInput">Rows</label>
          <div class="p-col-8">
            <p-inputNumber
              [(ngModel)]="configuration.subtitleRowCount"
              [max]="SUBTITLE_ROW_COUNT_MAX"
              [min]="SUBTITLE_ROW_COUNT_MIN"
              [showButtons]="true"
              [step]="SUBTITLE_ROW_COUNT_STEP"
              [useGrouping]="false"
              id="subtitleRowCountInput"
            ></p-inputNumber>

            <p-slider
              [(ngModel)]="configuration.subtitleRowCount"
              [max]="SUBTITLE_ROW_COUNT_MAX"
              [min]="SUBTITLE_ROW_COUNT_MIN"
              [step]="SUBTITLE_ROW_COUNT_STEP"
              id="subtitleRowCountSlider"
            ></p-slider>
          </div>
        </div>

        <div class="p-field p-grid">
          <label class="p-col-3" for="marginInput">Characters per row</label>
          <div class="p-col-">
            <p-inputNumber
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.printMargin"
              [max]="MARGIN_MAX"
              [min]="MARGIN_MIN"
              [showButtons]="true"
              [step]="MARGIN_STEP"
              [useGrouping]="false"
              id="marginInput"
            ></p-inputNumber>

            <p-slider
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.printMargin"
              [max]="MARGIN_MAX"
              [min]="MARGIN_MIN"
              [step]="MARGIN_STEP"
              id="marginSlider"
            ></p-slider>
          </div>
        </div>

        <div class="p-field p-grid">
          <!--suppress XmlInvalidId -->
          <label class="p-col-4" for="wrap">Wrap</label>

          <div class="p-col-8">
            <p-checkbox
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.wrap"
              binary="true"
              inputId="wrap"
            ></p-checkbox>
            <!--suppress XmlInvalidId -->
          </div>
        </div>

        <div class="p-field p-grid">
          <!--suppress XmlInvalidId -->
          <label class="p-col-4" for="showMargin">Show margin</label>

          <div class="p-col-8">
            <p-checkbox
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.showPrintMargin"
              binary="true"
              inputId="showMargin"
            ></p-checkbox>
            <!--suppress XmlInvalidId -->
          </div>
        </div>

        <div class="p-field p-grid">
          <!--suppress XmlInvalidId -->
          <label class="p-col-4" for="showInvisibles">Show whitespace</label>

          <div class="p-col-8">
            <p-checkbox
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.showInvisibles"
              binary="true"
              inputId="showInvisibles"
            ></p-checkbox>
            <!--suppress XmlInvalidId -->
          </div>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Output">
      <div class="p-fluid">
        <div class="p-field p-grid">
          <label class="p-col-4" for="subtitleCharacterDelayInput">Character delay</label>
          <div class="p-col-8">
            <p-inputNumber
              [(ngModel)]="configuration.subtitleCharacterDelay"
              [max]="SUBTITLE_CHARACTER_DELAY_MAX"
              [min]="SUBTITLE_CHARACTER_DELAY_MIN"
              [showButtons]="true"
              [step]="SUBTITLE_CHARACTER_DELAY_STEP"
              [useGrouping]="false"
              id="subtitleCharacterDelayInput"
            ></p-inputNumber>

            <p-slider
              [(ngModel)]="configuration.subtitleCharacterDelay"
              [max]="SUBTITLE_CHARACTER_DELAY_MAX"
              [min]="SUBTITLE_CHARACTER_DELAY_MIN"
              [step]="SUBTITLE_CHARACTER_DELAY_STEP"
              id="subtitleCharacterDelaySlider"
            ></p-slider>
          </div>
        </div>

        <div class="p-field p-grid">
          <!--suppress XmlInvalidId -->
          <label class="p-col-4" for="subtitleAnimation">Animation</label>

          <div class="p-col-8">
            <p-checkbox
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.subtitleAnimation"
              binary="true"
              inputId="subtitleAnimation"
            ></p-checkbox>
          </div>
        </div>

        <div class="p-field p-grid">
          <!--suppress XmlInvalidId -->
          <label class="p-col-4" for="subtitleAsInlineText">Subtitle as inline text</label>

          <div class="p-col-8">
            <p-checkbox
              (ngModelChange)="onSubtitleTextEditorOptionsChanged()"
              [(ngModel)]="configuration.subtitleAsInlineText"
              binary="true"
              inputId="subtitleAsInlineText"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <p-footer>
    <div class="p-d-flex p-jc-between">
      <button (click)="resetSettings()" class="p-button-warning" icon="pi pi-trash" label="Reset" pButton type="button"></button>
      <button (click)="dismissSettings()" class="p-button-success" icon="pi pi-check" label="OK" pButton type="button"></button>
    </div>
  </p-footer>
</p-dialog>
