/* eslint-disable @typescript-eslint/naming-convention */
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common';
import { escape } from 'he';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Menu } from 'primeng/menu';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import {
  Configuration,
  ConfigurationDefaults,
  DropDownOption,
  OutputIdentifier,
  processDelta,
  Style,
  TextTools,
  WebSocketMessage,
  WebSocketMessageDataConfiguration,
  WebSocketMessageDataDelta,
  WebSocketMessageDataText,
  WEBSOCKET_RECONNECT_DELAY,
} from '../app.common';
import { LiveSubApiService } from '../live-sub-api.service';

interface OffsetInformation {
  invisibleLineOffsets: number[];
  firstVisibleOffset: number;
}

@Component({
  selector: 'app-transcript-page',
  templateUrl: './display-page.component.html',
  styleUrls: ['./display-page.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class DisplayPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  readonly FONT_SIZE_MIN = 8;
  readonly FONT_SIZE_MAX = 256;
  readonly FONT_SIZE_STEP = 1;

  readonly LINE_HEIGHT_MIN = 0.8;
  readonly LINE_HEIGHT_MAX = 2.0;
  readonly LINE_HEIGHT_STEP = 0.01;

  readonly BORDER_SIZE_MIN = 0;
  readonly BORDER_SIZE_MAX = 32;
  readonly BORDER_SIZE_STEP = 1;

  readonly SPEECH_SAFEGUARD_DELAY = 3000;

  readonly PRESET_COLORS = ['#000000', '#ff0000', '#00ff00', '#ffff00', '#0000ff', '#ff00ff', '#00ffff', '#ffffff'];

  siteIdentifier: string;

  outputIdentifier: OutputIdentifier;
  configuration: Configuration;
  fontFamilyOptions: DropDownOption[];
  fontFamilyOption: DropDownOption;
  fontWeightOptions: DropDownOption[];
  fontWeightOption: DropDownOption;

  text: string;
  speech: string;
  textHtml: SafeHtml;
  speechHtml: SafeHtml;
  textOverride: string;

  isRestoreSettingsDialogVisible = false;
  dialogRestoreSettingsText = '';
  menuItems: MenuItem[];

  @ViewChild('output') output!: ElementRef;
  @ViewChild('menu') menu!: Menu;

  private readonly DRAG_BORDER_SIZE = 3;
  private readonly DRAGGING_OPACITY = 0.8;
  private readonly QUERY_PARAM_STATE = 'state';
  private readonly STATE_UPDATE_INTERVAL = 1000;
  private readonly fontFamilyList = [
    'sans-serif',
    'serif',
    'monospace',
    'DejaVu Sans',
    'DejaVu Sans Mono',
    'DejaVu Serif',
    'Droid Sans',
    'Droid Sans Mono',
    'Droid Serif',
    'Roboto',
    'Roboto Condensed',
    'Bedstead',
  ];
  private readonly fontWeightList = ['normal', 'bold'];
  private webSocket: WebSocket;
  private isSettingsPanelVisible = false;
  private isSliderDragging = false;
  private scrollCheckRequired = false;
  private speechSafeguardSubscription: Subscription;
  private resizeObservable: Observable<Event>;
  private resizeSubscription: Subscription;
  private offsetInformation: OffsetInformation = { firstVisibleOffset: 0, invisibleLineOffsets: [] };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: Storage,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private apiService: LiveSubApiService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private sanitizer: DomSanitizer,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.siteIdentifier = this.route.snapshot.paramMap.get('identifier');

    this.loadComponent();

    this.title.setTitle(`LiveSub: ${this.siteIdentifier}/${this.outputIdentifier}`);
  }

  private static createConfiguration(): Configuration {
    return {
      positionLeft: ConfigurationDefaults.DEFAULT_POSITION_LEFT,
      positionTop: ConfigurationDefaults.DEFAULT_POSITION_TOP,
      positionRight: ConfigurationDefaults.DEFAULT_POSITION_RIGHT,
      positionBottom: ConfigurationDefaults.DEFAULT_POSITION_BOTTOM,
      paddingLeft: ConfigurationDefaults.DEFAULT_PADDING_LEFT,
      paddingTop: ConfigurationDefaults.DEFAULT_PADDING_TOP,
      paddingRight: ConfigurationDefaults.DEFAULT_PADDING_RIGHT,
      paddingBottom: ConfigurationDefaults.DEFAULT_PADDING_BOTTOM,
      backgroundOpacity: ConfigurationDefaults.DEFAULT_BACKGROUND_OPACITY,
      fontFamily: ConfigurationDefaults.DEFAULT_FONT_FAMILY,
      fontWeight: ConfigurationDefaults.DEFAULT_FONT_WEIGHT,
      fontSize: ConfigurationDefaults.DEFAULT_FONT_SIZE,
      lineHeight: ConfigurationDefaults.DEFAULT_LINE_HEIGHT,
      textColor: ConfigurationDefaults.DEFAULT_TEXT_COLOR,
      backgroundColor: ConfigurationDefaults.DEFAULT_BACKGROUND_COLOR,
      borderSize: ConfigurationDefaults.DEFAULT_BORDER_SIZE,
      borderColor: ConfigurationDefaults.DEFAULT_BORDER_COLOR,
      outlineColor: ConfigurationDefaults.DEFAULT_OUTLINE_COLOR,
      outlineVisible: ConfigurationDefaults.DEFAULT_OUTLINE_VISIBLE,
      logoVisible: ConfigurationDefaults.DEFAULT_LOGO_VISIBLE,
      logoLeft: ConfigurationDefaults.DEFAULT_LOGO_LEFT,
      logoTop: ConfigurationDefaults.DEFAULT_LOGO_TOP,
      logoWidth: ConfigurationDefaults.DEFAULT_LOGO_WIDTH,
      logoHeight: ConfigurationDefaults.DEFAULT_LOGO_HEIGHT,
    };
  }

  private static encodeState(configuration: Configuration): string {
    return btoa(
      JSON.stringify({
        positionLeft: configuration.positionLeft,
        positionTop: configuration.positionTop,
        positionRight: configuration.positionRight,
        positionBottom: configuration.positionBottom,
        paddingLeft: configuration.paddingLeft,
        paddingTop: configuration.paddingTop,
        paddingRight: configuration.paddingRight,
        paddingBottom: configuration.paddingBottom,
        backgroundOpacity: configuration.backgroundOpacity,
        fontFamily: configuration.fontFamily,
        fontWeight: configuration.fontWeight,
        fontSize: configuration.fontSize,
        lineHeight: configuration.lineHeight,
        textColor: configuration.textColor,
        backgroundColor: configuration.backgroundColor,
        borderSize: configuration.borderSize,
        borderColor: configuration.borderColor,
        outlineColor: configuration.outlineColor,
        outlineVisible: configuration.outlineVisible,
        logoVisible: configuration.logoVisible,
        logoLeft: configuration.logoLeft,
        logoTop: configuration.logoTop,
        logoWidth: configuration.logoWidth,
        logoHeight: configuration.logoHeight,
      })
    );
  }

  private static decodeState(stateString: string): Configuration {
    const stateObject = JSON.parse(atob(stateString)) as Configuration;

    if (stateObject.paddingLeft === undefined) {
      stateObject.paddingLeft = 0;
    }

    if (stateObject.paddingTop === undefined) {
      stateObject.paddingTop = 0;
    }

    if (stateObject.paddingRight === undefined) {
      stateObject.paddingRight = 0;
    }

    if (stateObject.paddingBottom === undefined) {
      stateObject.paddingBottom = 0;
    }

    if (stateObject.backgroundOpacity === undefined) {
      stateObject.backgroundOpacity = 0;
    }

    return {
      positionLeft: stateObject.positionLeft,
      positionTop: stateObject.positionTop,
      positionRight: stateObject.positionRight,
      positionBottom: stateObject.positionBottom,
      paddingLeft: stateObject.paddingLeft,
      paddingTop: stateObject.paddingTop,
      paddingRight: stateObject.paddingRight,
      paddingBottom: stateObject.paddingBottom,
      backgroundOpacity: stateObject.backgroundOpacity,
      fontFamily: stateObject.fontFamily,
      fontWeight: stateObject.fontWeight,
      fontSize: stateObject.fontSize,
      lineHeight: stateObject.lineHeight,
      textColor: stateObject.textColor,
      backgroundColor: stateObject.backgroundColor,
      borderSize: stateObject.borderSize,
      borderColor: stateObject.borderColor,
      outlineColor: stateObject.outlineColor,
      outlineVisible: stateObject.outlineVisible,
      logoVisible: stateObject.logoVisible,
      logoLeft: stateObject.logoLeft,
      logoTop: stateObject.logoTop,
      logoWidth: stateObject.logoWidth,
      logoHeight: stateObject.logoHeight,
    };
  }

  ngOnInit(): void {
    const queryStateConfiguration = this.getQueryState();

    if (queryStateConfiguration) {
      this.configuration = queryStateConfiguration;
    } else {
      const localStorageConfiguration = this.loadConfiguration();

      if (localStorageConfiguration) {
        this.configuration = localStorageConfiguration;
      } else {
        this.configuration = DisplayPageComponent.createConfiguration();
      }
    }

    this.updateQueryState();
    this.storeConfiguration(this.configuration);

    this.fontFamilyOptions = [];
    this.fontFamilyList.forEach((value) => this.fontFamilyOptions.push({ value, label: value }));
    this.updateFontFamilyObject();

    this.fontWeightOptions = [];
    this.fontWeightList.forEach((value) => this.fontWeightOptions.push({ value, label: value }));
    this.updateFontWeightObject();

    this.text = '';
    this.speech = '';
    this.textHtml = '';
    this.speechHtml = '';
    this.textOverride = '';

    this.reformatHtml();

    this.menuItems = [
      { label: 'Copy all settings', icon: 'pi pi-fw pi-copy', command: () => this.copySettings() },
      { label: 'Restore', icon: 'pi pi-fw pi-upload', command: () => this.restoreSettings() },
      { label: 'Reset', icon: 'pi pi-fw pi-trash', command: () => this.resetSettings() },
    ];

    this.apiService.retrieveCurrentTextSegment().subscribe((value) => {
      this.text = value;
      this.reformatHtml();

      this.createWebSocket();
    });

    this.resizeObservable = fromEvent<Event>(window, 'resize');
    this.resizeSubscription = this.resizeObservable.subscribe(() => {
      this.requireScrollCheck();
    });

    timer(0, this.STATE_UPDATE_INTERVAL).subscribe(() => {
      try {
        this.updateState();
      } catch (e) {
        console.error(e);
      }
    });
  }

  ngOnDestroy(): void {
    this.resizeSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (this.scrollCheckRequired) {
      this.scrollCheckRequired = false;
      this.scrollToBottom();
    }

    const offsetInformation = this.getOffsetInformation();

    if (!offsetInformation) {
      return;
    }

    if (this.speech.length === 0) {
      if (this.offsetInformation.firstVisibleOffset !== offsetInformation.firstVisibleOffset) {
        this.sendOffsetInformation(offsetInformation);
        this.offsetInformation = offsetInformation;
      }
    }
  }

  getSettingsPanelStyle(): Style {
    return {
      opacity: this.isSliderDragging ? this.DRAGGING_OPACITY : 1.0,
      display: this.isSettingsPanelVisible ? 'block' : 'none',
    };
  }

  getContainerStyle(): Style {
    return {
      'background-color': this.outputIsKey() ? 'black' : this.configuration.backgroundColor,
      'font-smooth': this.outputIsKey() ? 'never' : 'auto',
    };
  }

  getOutputStyle(): Style {
    const visible = !this.outputIsKey() && (this.isSliderDragging || this.configuration.outlineVisible);

    const level = this.configuration.backgroundOpacity;

    return {
      left: `${this.configuration.positionLeft}px`,
      top: `${this.configuration.positionTop}px`,
      right: `${this.configuration.positionRight}px`,
      bottom: `${this.configuration.positionBottom}px`,
      'font-family': this.configuration.fontFamily,
      'font-weight': this.configuration.fontWeight,
      'font-size': `${this.configuration.fontSize / 10}em`,
      'line-height': this.configuration.lineHeight,
      'padding-left': `${this.configuration.paddingLeft + this.configuration.borderSize / 2}px`,
      'padding-top': `${this.configuration.paddingTop + this.configuration.borderSize / 2}px`,
      'padding-right': `${this.configuration.paddingRight + this.configuration.borderSize / 2}px`,
      'padding-bottom': `${this.configuration.paddingBottom + this.configuration.borderSize / 2}px`,
      color: this.outputIsKey() ? 'white' : this.configuration.textColor,
      'border-color': this.configuration.outlineColor,
      'border-style': visible ? 'dotted' : 'none',
      'border-width': visible ? `${this.DRAG_BORDER_SIZE}px` : '0px',
      'margin-left': visible ? `-${this.DRAG_BORDER_SIZE}px` : '0px',
      'margin-top': visible ? `-${this.DRAG_BORDER_SIZE}px` : '0px',
      'margin-right': visible ? `-${this.DRAG_BORDER_SIZE}px` : '0px',
      'margin-bottom': visible ? `-${this.DRAG_BORDER_SIZE}px` : '0px',
      'font-smooth': this.outputIsKey() ? 'never' : 'auto',
      'background-color': this.outputIsKey() ? `rgb(${level},${level},${level})` : 'transparent',
    };
  }

  getOutputBorderStyle(): Style {
    return {
      '-webkit-text-stroke-width': `${this.configuration.borderSize + (this.outputIsKey() ? 1 : 0)}px`,
      '-webkit-text-stroke-color': this.outputIsKey() ? 'white' : this.configuration.borderColor,
    };
  }

  getOutputTextStyle(): Style {
    return {
      '-webkit-text-stroke-width': `${this.configuration.borderSize}px`,
    };
  }

  getTranscriptTextBorderStyle(): Style {
    return {};
  }

  getTranscriptTextStyle(): Style {
    return {};
  }

  getTranscriptSpeechBorderStyle(): Style {
    return { display: this.speech.length > 0 ? 'inline' : 'none' };
  }

  getTranscriptSpeechStyle(): Style {
    return { display: this.speech.length > 0 ? 'inline' : 'none' };
  }

  onSliderChange(): void {
    this.isSliderDragging = true;
  }

  onSliderEnd(): void {
    this.isSliderDragging = false;
  }

  toggleSettings($event: MouseEvent): void {
    let allowed = true;

    if (!this.isSettingsPanelVisible) {
      allowed = $event.altKey || $event.ctrlKey || $event.metaKey || $event.shiftKey;
    }

    if (allowed) {
      if (this.isSettingsPanelVisible) {
        this.dismissSettings();
      } else {
        this.isSettingsPanelVisible = true;
      }

      this.isSliderDragging = false; // failsafe for keyboard dragging
    }
  }

  resetSettings(): void {
    this.confirmationService.confirm({
      message: 'Reset settings?',
      acceptLabel: 'Reset',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-times',
      icon: 'pi pi-trash',
      accept: () => {
        this.configuration = DisplayPageComponent.createConfiguration();

        this.messageService.add({
          severity: 'success',
          summary: 'Settings restored to default',
        });
      },
    });
  }

  copySettings(): void {
    const stateString = DisplayPageComponent.encodeState(this.configuration);

    this.copyTextToClipboard(stateString);

    this.messageService.add({
      severity: 'success',
      summary: 'Settings copied to clipboard',
    });
  }

  restoreSettings(): void {
    this.isRestoreSettingsDialogVisible = true;
  }

  dismissSettings(): void {
    this.isSettingsPanelVisible = false;
    this.menu.hide();
    this.updateState();
    this.requireScrollCheck();
  }

  onFontFamilyChanged(): void {
    this.configuration.fontFamily = this.fontFamilyOption.value;
  }

  onFontWeightChanged(): void {
    this.configuration.fontWeight = this.fontWeightOption.value;
  }

  formatTextEngine(leftText: string, span: boolean, rightText: string): SafeHtml {
    const addWord = () => {
      let wordHtml = escape(wordText);

      if (span) {
        wordHtml = `<span id="word-${characterOffset}" data-offset="${wordOffset}" data-length="${wordText.length}" data-word="${wordText}">${wordHtml}</span>`;
      }

      words.push(wordHtml);
    };

    let characterOffset = 0;
    let wordOffset = 0;
    let wordText = '';

    const words: string[] = [];

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < leftText.length; i++) {
      if (leftText[i] === ' ' || leftText[i] === '\n') {
        if (wordText.length > 0) {
          addWord();
          characterOffset += 1;
        }

        if (leftText[i] === '\n') {
          words.push('<br>');
        }

        wordText = '';
      } else {
        if (wordText.length === 0) {
          wordOffset = i;
        }

        wordText += leftText[i];
      }
    }

    if (wordText.length) {
      addWord();
    }

    if (!TextTools.isGlueRequired(leftText, rightText)) {
      words[words.length - 1] += escape(' ');
    }

    return this.sanitizer.bypassSecurityTrustHtml(words.join(' '));
  }

  outputIsMain(): boolean {
    return this.outputIdentifier === 'main';
  }

  outputIsFill(): boolean {
    return this.outputIdentifier === 'fill';
  }

  outputIsKey(): boolean {
    return this.outputIdentifier === 'key';
  }

  onTextOverrideModelChange(event: string): void {
    this.textOverride = event;
    this.reformatHtml();
  }

  onRestoreSettings(): void {
    const payload = this.dialogRestoreSettingsText.trim();

    this.configuration = DisplayPageComponent.decodeState(payload);

    this.isRestoreSettingsDialogVisible = false;

    this.messageService.add({
      severity: 'success',
      summary: 'Settings restored',
    });
  }

  copyTextToClipboard(text: string): void {
    const textArea = this.document.createElement('textarea');

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    this.document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      if (!this.document.execCommand('copy')) {
        console.log('copyTextToClipboard: unable to copy');
      }
    } catch (err) {
      console.log('copyTextToClipboard: operation failed');
    }

    this.document.body.removeChild(textArea);
  }

  validRestoreSettingsText(): boolean {
    if (!this.isRestoreSettingsDialogVisible) {
      return false;
    }

    const payload = this.dialogRestoreSettingsText.trim();

    if (payload.length === 0) {
      return false;
    }

    try {
      DisplayPageComponent.decodeState(payload);
    } catch (e) {
      return false;
    }

    return payload !== '';
  }

  showTranscript(): void {
    this.router.navigate(['transcript']).then();
  }

  private getOffsetInformation(): OffsetInformation | null {
    let index = 0;

    const firstElement: HTMLElement = this.document.getElementById('word-0');

    if (!firstElement) {
      return { invisibleLineOffsets: [], firstVisibleOffset: 0 };
    }

    const invisibleLineOffsets = [];

    let invisibleElement: HTMLElement = null;

    // noinspection InfiniteLoopJS
    while (true) {
      const element: HTMLElement = this.document.getElementById(`word-${index}`);

      if (!element) {
        break;
      }

      if (!this.isVisibleElement(element)) {
        invisibleElement = element;

        if (element.offsetLeft === firstElement.offsetLeft) {
          const textOffset = parseInt(invisibleElement.dataset.offset, 10);

          invisibleLineOffsets.push(textOffset);
        }
      } else {
        let firstVisibleOffset = 0;

        if (invisibleElement) {
          firstVisibleOffset = parseInt(invisibleElement.dataset.offset, 10) + parseInt(invisibleElement.dataset.length, 10);
        }

        while (this.text[firstVisibleOffset] === ' ' || this.text[firstVisibleOffset] === '\n') {
          firstVisibleOffset += 1;
        }

        return { invisibleLineOffsets, firstVisibleOffset };
      }

      index++;
    }
  }

  private isVisibleElement(element: HTMLElement): boolean {
    return element.offsetTop + element.offsetHeight > (this.output.nativeElement as HTMLElement).scrollTop;
  }

  private loadComponent(): void {
    const component = this.route.snapshot.paramMap.get('component');

    switch (component) {
      case 'main':
        this.outputIdentifier = component as OutputIdentifier;
        break;

      case 'fill':
        this.outputIdentifier = component as OutputIdentifier;
        break;

      case 'key':
        this.outputIdentifier = component as OutputIdentifier;
        break;

      default:
        this.router.navigate(['display', this.siteIdentifier, 'main']).then();
        break;
    }
  }

  private createWebSocket(): void {
    this.webSocket = new WebSocket(this.buildWebSocketUrl());

    this.webSocket.onopen = (ev) => {
      console.log(`webSocket.onopen: ${JSON.stringify(ev)}`);
    };

    this.webSocket.onerror = (ev) => {
      console.log(`webSocket.onerror: ${JSON.stringify(ev)}`);
    };

    this.webSocket.onclose = (ev) => {
      console.log(`webSocket.onclose: ${JSON.stringify(ev)}`);

      timer(WEBSOCKET_RECONNECT_DELAY).subscribe(() => {
        this.createWebSocket();
      });
    };

    this.webSocket.onmessage = (ev) => {
      this.handleWebSocketMessage(JSON.parse(ev.data) as WebSocketMessage);
    };
  }

  private handleWebSocketMessage(message: WebSocketMessage): void {
    switch (message.message_type) {
      case 'configuration': {
        this.handleConfigurationMessage(message.message_data as WebSocketMessageDataConfiguration);
        break;
      }

      case 'text': {
        this.handleTextMessage(message.message_data as WebSocketMessageDataText);
        break;
      }

      case 'delta': {
        this.handleDeltaMessage(message.message_data as WebSocketMessageDataDelta);
        break;
      }

      default:
        break;
    }
  }

  private handleConfigurationMessage(message: WebSocketMessageDataConfiguration): void {
    if (this.siteIdentifier !== message.site_identifier) {
      return;
    }

    if (!this.outputIsKey()) {
      return;
    }

    this.configuration = message.configuration;
    this.reformatHtml();
  }

  private handleTextMessage(message: WebSocketMessageDataText): void {
    this.textOverride = '';
    this.text = message.text;
    this.reformatHtml();
  }

  private handleDeltaMessage(message: WebSocketMessageDataDelta): void {
    this.textOverride = '';
    this.text = processDelta(this.text, message.text_operations);
    this.speech = processDelta(this.speech, message.speech_operations);
    this.scheduleSpeechSafeguard();
    this.reformatHtml();
  }

  private buildWebSocketUrl(): string {
    const protocol = this.window.location.protocol === 'https:' ? 'wss://' : 'ws://';
    return protocol + this.window.location.host + '/api/websocket';
  }

  private buildApiUrl(path: string): string {
    return `${this.baseHref}api${path}`;
  }

  private updateState(): void {
    const stateString = DisplayPageComponent.encodeState(this.configuration);

    if (this.getQueryStateString() === stateString) {
      return;
    }

    this.requireScrollCheck();
    this.updateQueryStateString(stateString);
    this.storeConfiguration(this.configuration);
    this.uploadConfiguration();
  }

  private uploadConfiguration(): void {
    if (!this.outputIsFill()) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'configuration',
      message_data: {
        site_identifier: this.siteIdentifier,
        configuration: this.configuration,
      },
    };

    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    this.webSocket.send(JSON.stringify(message));
  }

  private storeConfiguration(configuration: Configuration): void {
    this.localStorage.setItem(this.storageConfigurationKey(), JSON.stringify(configuration));
  }

  private loadConfiguration(): Configuration | null {
    const configurationString = this.localStorage.getItem(this.storageConfigurationKey());

    if (!configurationString) {
      return null;
    }

    return JSON.parse(configurationString) as Configuration;
  }

  private storageConfigurationKey(): string {
    return `${this.siteIdentifier}:${this.outputIdentifier}`;
  }

  private updateQueryStateString(stateString: string): void {
    this.router
      .navigate([], {
        queryParams: { state: stateString },
        queryParamsHandling: 'merge',
      })
      .then();
  }

  private getQueryStateString(): string | null {
    return this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_STATE);
  }

  private updateQueryState(): void {
    this.updateQueryStateString(DisplayPageComponent.encodeState(this.configuration));
  }

  private getQueryState(): Configuration | null {
    const stateString = this.getQueryStateString();

    if (!stateString) {
      return null;
    }

    return DisplayPageComponent.decodeState(stateString);
  }

  private requireScrollCheck(): void {
    this.scrollCheckRequired = true;
  }

  private scrollToBottom(): boolean {
    const element = this.output.nativeElement as HTMLElement;

    const nextScrollTop = element.scrollHeight - element.clientHeight;

    if (element.scrollTop !== nextScrollTop) {
      element.scrollTop = nextScrollTop;
      return true;
    }

    return false;
  }

  private sendOffsetInformation(offsetInformation: OffsetInformation): void {
    return;

    if (this.webSocket.readyState !== WebSocket.OPEN) {
      return;
    }

    const message: WebSocketMessage = {
      message_type: 'offset_information',
      message_data: {
        first_visible_offset: offsetInformation.firstVisibleOffset,
        invisible_line_offsets: offsetInformation.invisibleLineOffsets,
      },
    };

    this.webSocket.send(JSON.stringify(message));
  }

  private updateFontFamilyObject(): void {
    this.fontFamilyOption = this.fontFamilyOptions.find((x) => x.value === this.configuration.fontFamily);
  }

  private updateFontWeightObject(): void {
    this.fontWeightOption = this.fontWeightOptions.find((x) => x.value === this.configuration.fontWeight);
  }

  private reformatHtml(): void {
    if (this.textOverride.length > 0) {
      this.textHtml = this.formatTextEngine(this.textOverride, false, '');
      this.speechHtml = this.formatTextEngine('', false, '');
    } else {
      this.textHtml = this.formatTextEngine(this.text, false, this.speech); // was: true
      this.speechHtml = this.formatTextEngine(this.speech, false, '');
    }

    this.requireScrollCheck();
  }

  private scheduleSpeechSafeguard(): void {
    if (this.speechSafeguardSubscription) {
      this.speechSafeguardSubscription.unsubscribe();
    }

    this.speechSafeguardSubscription = timer(this.SPEECH_SAFEGUARD_DELAY).subscribe(() => {
      this.speech = '';
      this.reformatHtml();
    });
  }
}
