<div class="p-d-flex">
  <p-card class="p-sm-4 p-sm-offset-4" header="Recover Password">
    <div class="p-fluid">
      <div class="p-field">
        <input id="login" pInputText placeholder="Email" type="text" />
      </div>
    </div>
    <p-footer>
      <div class="p-grid p-my-0 p-mx-0">
        <div class="p-sm-6 p-sm-offset-3">
          <button label="Reset password" pButton style="width: 100%" type="button"></button>
        </div>
      </div>
    </p-footer>
  </p-card>
</div>
