<div (click)="toggleSettings($event)" [style]="getContainerStyle()" id="container">
  <div (click)="$event.stopPropagation()" [style]="getSettingsPanelStyle()" id="display-settings-panel">
    <p-card [header]="'Settings for ' + this.siteIdentifier + ' (' + this.outputIdentifier + ')'">
      <p-tabView>
        <p-tabPanel header="Position">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label class="p-col-4" for="positionLeftInput">Left</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.positionLeft"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="positionLeftInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.positionLeft"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  id="positionLeftSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="positionTopInput">Top</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.positionTop"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="positionTopInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.positionTop"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  id="positionTopSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="positionRightInput">Right</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.positionRight"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="positionRightInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.positionRight"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  id="positionRightSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="positionBottomInput">Bottom</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.positionBottom"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="positionBottomInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.positionBottom"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  id="positionBottomSlider"
                ></p-slider>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Padding">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label class="p-col-4" for="paddingLeftInput">Left</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.paddingLeft"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="paddingLeftInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.paddingLeft"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  id="paddingLeftSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="paddingTopInput">Top</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.paddingTop"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="paddingTopInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.paddingTop"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  id="paddingTopSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="paddingRightInput">Right</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.paddingRight"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="paddingRightInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.paddingRight"
                  [disabled]="outputIsKey()"
                  [max]="1920"
                  [min]="0"
                  id="paddingRightSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="paddingBottomInput">Bottom</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.paddingBottom"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="paddingBottomInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.paddingBottom"
                  [disabled]="outputIsKey()"
                  [max]="1080"
                  [min]="0"
                  id="paddingBottomSlider"
                ></p-slider>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Font">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label class="p-col-4" for="fontFamily">Font</label>
              <div class="p-col-8">
                <p-dropdown
                  (onChange)="onFontFamilyChanged()"
                  [(ngModel)]="fontFamilyOption"
                  [disabled]="outputIsKey()"
                  [options]="fontFamilyOptions"
                  id="fontFamily"
                  optionLabel="label"
                  placeholder="Font family"
                ></p-dropdown>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="fontWeight">Weight</label>
              <div class="p-col-8">
                <p-dropdown
                  (onChange)="onFontWeightChanged()"
                  [(ngModel)]="fontWeightOption"
                  [disabled]="outputIsKey()"
                  [options]="fontWeightOptions"
                  id="fontWeight"
                  optionLabel="label"
                  placeholder="Font weight"
                ></p-dropdown>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="fontSizeInput">Size</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.fontSize"
                  [disabled]="outputIsKey()"
                  [max]="FONT_SIZE_MAX"
                  [min]="FONT_SIZE_MIN"
                  [showButtons]="true"
                  [step]="FONT_SIZE_STEP"
                  [useGrouping]="false"
                  id="fontSizeInput"
                ></p-inputNumber>

                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.fontSize"
                  [disabled]="outputIsKey()"
                  [max]="FONT_SIZE_MAX"
                  [min]="FONT_SIZE_MIN"
                  [step]="FONT_SIZE_STEP"
                  id="fontSizeSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="lineHeightInput">Line height</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.lineHeight"
                  [disabled]="outputIsKey()"
                  [max]="LINE_HEIGHT_MAX"
                  [min]="LINE_HEIGHT_MIN"
                  [showButtons]="true"
                  [step]="LINE_HEIGHT_STEP"
                  [useGrouping]="false"
                  id="lineHeightInput"
                ></p-inputNumber>

                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.lineHeight"
                  [disabled]="outputIsKey()"
                  [max]="LINE_HEIGHT_MAX"
                  [min]="LINE_HEIGHT_MIN"
                  [step]="LINE_HEIGHT_STEP"
                  id="lineHeightSlider"
                ></p-slider>
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="borderSizeInput">Border size</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.borderSize"
                  [disabled]="outputIsKey()"
                  [max]="BORDER_SIZE_MAX"
                  [min]="BORDER_SIZE_MIN"
                  [showButtons]="true"
                  [step]="BORDER_SIZE_STEP"
                  [useGrouping]="false"
                  id="borderSizeInput"
                ></p-inputNumber>

                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.borderSize"
                  [disabled]="outputIsKey()"
                  [max]="BORDER_SIZE_MAX"
                  [min]="BORDER_SIZE_MIN"
                  [step]="BORDER_SIZE_STEP"
                  id="borderSizeSlider"
                ></p-slider>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Colors">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label class="p-col-4" for="textColor">Text color</label>
              <div class="p-col-8">
                <input
                  [(colorPicker)]="configuration.textColor"
                  [cpPresetColors]="PRESET_COLORS"
                  [disabled]="outputIsKey()"
                  [style.background]="configuration.textColor"
                  class="color-selector"
                  cpAlphaChannel="disabled"
                  cpCancelButton="true"
                  cpOKButton="true"
                  cpOutputFormat="hex"
                  cpPosition="bottom"
                  id="textColor"
                  readonly
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="backgroundColor">Background color</label>
              <div class="p-col-8">
                <input
                  [(colorPicker)]="configuration.backgroundColor"
                  [cpPresetColors]="PRESET_COLORS"
                  [disabled]="outputIsKey()"
                  [style.background]="configuration.backgroundColor"
                  class="color-selector"
                  cpAlphaChannel="disabled"
                  cpCancelButton="true"
                  cpOKButton="true"
                  cpOutputFormat="hex"
                  cpPosition="bottom"
                  id="backgroundColor"
                  readonly
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="borderColor">Border color</label>
              <div class="p-col-8">
                <input
                  [(colorPicker)]="configuration.borderColor"
                  [cpPresetColors]="PRESET_COLORS"
                  [disabled]="outputIsKey()"
                  [style.background]="configuration.borderColor"
                  class="color-selector"
                  cpAlphaChannel="disabled"
                  cpCancelButton="true"
                  cpOKButton="true"
                  cpOutputFormat="hex"
                  cpPosition="bottom"
                  id="borderColor"
                  readonly
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="outlineColor">Outline color</label>
              <div class="p-col-8">
                <input
                  [(colorPicker)]="configuration.outlineColor"
                  [cpPresetColors]="PRESET_COLORS"
                  [disabled]="outputIsKey()"
                  [style.background]="configuration.outlineColor"
                  class="color-selector"
                  cpAlphaChannel="disabled"
                  cpCancelButton="true"
                  cpOKButton="true"
                  cpOutputFormat="hex"
                  cpPosition="bottom"
                  id="outlineColor"
                  readonly
                />
              </div>
            </div>

            <div class="p-field p-grid">
              <!--suppress XmlInvalidId -->
              <label class="p-col-4" for="outlineVisible">Outline visible</label>

              <div class="p-col-8">
                <p-checkbox
                  [(ngModel)]="configuration.outlineVisible"
                  [disabled]="outputIsKey()"
                  binary="true"
                  inputId="outlineVisible"
                ></p-checkbox>
                <!--suppress XmlInvalidId -->
              </div>
            </div>

            <div class="p-field p-grid">
              <label class="p-col-4" for="backgroundOpacityInput">Container opacity</label>
              <div class="p-col-8">
                <p-inputNumber
                  [(ngModel)]="configuration.backgroundOpacity"
                  [disabled]="outputIsKey()"
                  [max]="255"
                  [min]="0"
                  [showButtons]="true"
                  [useGrouping]="false"
                  id="backgroundOpacityInput"
                ></p-inputNumber>
                <p-slider
                  (onChange)="onSliderChange()"
                  (onSlideEnd)="onSliderEnd()"
                  [(ngModel)]="configuration.backgroundOpacity"
                  [disabled]="outputIsKey()"
                  [max]="255"
                  [min]="0"
                  id="backgroundOpacitySlider"
                ></p-slider>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Text">
          <div class="p-fluid">
            <div class="p-field p-grid">
              <label for="textOverride"></label>
              <textarea
                (ngModelChange)="onTextOverrideModelChange($event)"
                [ngModel]="textOverride"
                class="livesub-textarea"
                id="textOverride"
                pInputTextarea
              ></textarea>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>

      <p-footer>
        <div class="p-d-flex p-jc-between">
          <div>
            <button
              (click)="menu.toggle($event)"
              [disabled]="outputIsKey()"
              class="p-button"
              icon="pi pi-bars"
              label="Options"
              pButton
              type="button"
            ></button>

            <button
              (click)="showTranscript()"
              [disabled]="outputIsKey()"
              class="p-button p-button-info p-ml-2"
              label="Transcript"
              pButton
              type="button"
            ></button>
          </div>

          <button (click)="dismissSettings()" class="p-button-success" icon="pi pi-check" label="OK" pButton type="button"></button>
        </div>
      </p-footer>
    </p-card>
  </div>

  <div #output [style]="getOutputStyle()" id="output">
    <p [style]="getOutputBorderStyle()" id="output-border">
      <span [innerHTML]="this.textHtml" [style]="getTranscriptTextBorderStyle()" class="transcript-text-border"></span>
      <span [innerHTML]="this.speechHtml" [style]="getTranscriptSpeechBorderStyle()" class="transcript-speech-border"></span>
    </p>

    <p [style]="getOutputTextStyle()" id="output-text">
      <span [innerHTML]="this.textHtml" [style]="getTranscriptTextStyle()" class="transcript-text"></span>
      <span [innerHTML]="this.speechHtml" [style]="getTranscriptSpeechStyle()" class="transcript-speech-text"></span>
    </p>
  </div>
</div>

<p-menu #menu [model]="menuItems" [popup]="true"></p-menu>

<p-confirmDialog [style]="{ width: '30%' }" header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-toast position="top-right"></p-toast>

<p-dialog
  [(visible)]="isRestoreSettingsDialogVisible"
  [style]="{ width: '50%' }"
  closeOnEscape="true"
  dismissableMask="true"
  header="Restore settings"
  modal="true"
>
  <form (ngSubmit)="onRestoreSettings()">
    <div class="p-formgroup-inline">
      <div class="p-field" style="flex: 1">
        <label class="p-sr-only" for="value1">Name</label>
        <input [(ngModel)]="dialogRestoreSettingsText" autofocus id="value1" name="value" pInputText style="width: 100%" type="text" />
      </div>
      <button
        [disabled]="!validRestoreSettingsText()"
        class="p-button-success"
        icon="pi pi-check"
        label="Restore"
        pButton
        type="submit"
      ></button>
    </div>
  </form>
</p-dialog>
