import { APP_BASE_HREF } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { JSendResponse } from './app.common';

export class LiveSubApiServiceError implements Error {
  readonly name = 'LiveSubApiServiceError';

  message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export interface SubtitleProxy {
  identifier: string;
  title: string;
}

export interface SubtitleDocument {
  identifier: string;
  title: string;
  text: string;
}

@Injectable({
  providedIn: 'root',
})
export class LiveSubApiService {
  constructor(@Inject(APP_BASE_HREF) private baseHref: string, private http: HttpClient) {}

  private static handleResponse<T>(value: JSendResponse, subscriber: Subscriber<T>): void {
    if (value.status === 'success') {
      subscriber.next(value.data as T);
    } else {
      subscriber.error(new LiveSubApiServiceError(value.message));
    }
  }

  archiveTextSegment(text: string): Observable<any> {
    return new Observable<void>((subscriber) => {
      this.http.post<JSendResponse>(this.buildApiUrl('/archive'), { text }).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<any>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  storeCurrentTextSegment(text: string): Observable<any> {
    return new Observable<void>((subscriber) => {
      this.http.post<JSendResponse>(this.buildApiUrl('/current'), { text }).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<any>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  retrieveCurrentTextSegment(): Observable<string> {
    return new Observable<string>((subscriber) => {
      this.http.get<JSendResponse>(this.buildApiUrl('/current')).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<string>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  deleteArchive(): Observable<any> {
    return new Observable<void>((subscriber) => {
      this.http.delete<JSendResponse>(this.buildApiUrl('/archive'), {}).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<any>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  deleteCurrent(): Observable<any> {
    return new Observable<void>((subscriber) => {
      this.http.delete<JSendResponse>(this.buildApiUrl('/current'), {}).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<any>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  retrieveText(): Observable<string> {
    return new Observable<string>((subscriber) => {
      this.http.get<JSendResponse>(this.buildApiUrl('/text')).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<string>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  getDocumentProxyList(): Observable<SubtitleProxy[]> {
    return new Observable<SubtitleProxy[]>((subscriber) => {
      this.http.get<JSendResponse>(this.buildApiUrl('/document')).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<SubtitleProxy[]>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  createDocument(title: string, text: string): Observable<string> {
    return new Observable<string>((subscriber) => {
      this.http.post<JSendResponse>(this.buildApiUrl('/document'), { title, text }).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<string>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  readDocument(identifier: string): Observable<SubtitleDocument> {
    return new Observable<SubtitleDocument>((subscriber) => {
      this.http.get<JSendResponse>(this.buildApiUrl(`/document/${identifier}`)).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<SubtitleDocument>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  updateDocument(identifier: string, title: string, text: string): Observable<string> {
    return new Observable<string>((subscriber) => {
      this.http.put<JSendResponse>(this.buildApiUrl(`/document/${identifier}`), { title, text }).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<string>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  deleteDocument(identifier: string): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.http.delete<JSendResponse>(this.buildApiUrl(`/document/${identifier}`)).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<boolean>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  setDocumentOrder(identifierList: string[]): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.http.post<JSendResponse>(this.buildApiUrl(`/document_order`), identifierList).subscribe(
        (value: JSendResponse) => LiveSubApiService.handleResponse<boolean>(value, subscriber),
        (error) => subscriber.error(error)
      );
    });
  }

  private buildApiUrl(path): string {
    return `${this.baseHref}api${path}`;
  }
}
